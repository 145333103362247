import React from 'react';

const App = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <header className="bg-cover bg-center relative" style={{ height: "70vh", backgroundImage: "url('<image_link_here>')" }}>
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative z-10 flex flex-col items-center justify-center h-full text-white">
          <h1 className="text-5xl font-montserrat">Move together.</h1>
          <nav className="absolute top-0 left-0 w-full flex justify-start p-6">
            <a href="#home" className="text-xl font-open-sans">Home</a>
          </nav>
        </div>
      </header>
      <main className="flex flex-col lg:flex-row justify-center items-center p-8 bg-white" style={{ height: "30vh" }}>
        <section className="flex flex-col items-center text-center p-4 m-4 bg-gray-100 rounded-lg shadow-lg">
          <h2 className="text-2l font-montserrat">Competition for all</h2>
          <p className="mt-2 text-sm font-open-sans">Whether you want to do a weight loss challenge with family or train with your friends for a marathon - anyone can participate with their own devices.</p>
          <button className="mt-4 px-4 py-2 text-sm bg-black text-white rounded font-open-sans">Compete</button>
        </section>
        <section className="flex flex-col items-center text-center p-4 m-4 bg-gray-100 rounded-lg shadow-lg">
          <h2 className="text-2l font-montserrat">Your health is personal</h2>
          <p className="mt-2 text-sm font-open-sans">All of your health integrations will store data directly on your mobile device. You will be able to use natural language to search your data.</p>
          <button className="mt-4 px-4 py-2 text-sm bg-black text-white rounded font-open-sans">Data</button>
        </section>
        <section className="flex flex-col items-center text-center p-4 m-4 bg-gray-100 rounded-lg shadow-lg">
          <h2 className="text-2l font-montserrat">Seamless tracking</h2>
          <p className="mt-2 text-sm font-open-sans">We are constantly growing our support for new integrations. Have a device we don’t support? Let us know and we will get to work!</p>
          <button className="mt-4 px-4 py-2 text-sm bg-black text-white rounded font-open-sans">Integrate</button>
        </section>
      </main>
    </div>
  );
};

export default App;
